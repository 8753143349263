import React, { useState } from "react";
import PropTypes from "prop-types";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ children }) => {
  const [isMenuActive, setMenuActive] = useState(false);
  const [isOverlayActive, setOverlayActive] = useState(false);

  const toggleMenu = () => {
    // This is genius
    // Ensures that the opacity animation finishes before overlay is completely removed
    if (!isOverlayActive) {
      setOverlayActive(true);
      setTimeout(() => {
        setMenuActive(true);
      }, 300);
    } else {
      setMenuActive(false);
      setTimeout(() => {
        setOverlayActive(false);
      }, 300);
    }
  };

  return (
    <>
      <main
        id="main"
        className={`font-sans bg-light text-dark flex flex-col min-h-screen ${
          !isMenuActive || "overflow-hidden"
        }`}
      >
        <div className="flex-1 flex flex-col">
          <Header
            className="flex-shrink-0"
            toggleMenu={toggleMenu}
            isMenuActive={isMenuActive}
          />
          {children}
        </div>

        <div className="pin-b">
          <Footer />
        </div>
      </main>
      {isOverlayActive ? (
        <div
          className={`transition-standard ${isMenuActive ? "show" : "hide"}`}
          id="page-overlay"
        ></div>
      ) : null}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
