import React from "react";

import { Link } from "gatsby";

const NavLinks = () => {
  return (
    <>
      <Link to="/">
        <span>Home</span>
      </Link>
      <Link to="/topics">
        <span>Topics</span>
      </Link>
      <Link to="/classes">
        <span>Classes</span>
      </Link>
      <Link to="/blog">
        <span>Blog</span>
      </Link>
      <Link to="/about">
        <span>About</span>
      </Link>
      <Link to="/contact">
        <span>Contact</span>
      </Link>
      <Link to="/donate">
        <span>Donate</span>
      </Link>
    </>
  );
};

export default NavLinks;
