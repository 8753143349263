import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, article, keywords }) => {
  const { pathname } = useLocation(); // Path of the page
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    defaultDescription,
    baseUrl,
    defaultImage,
    defaultKeywords,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || baseUrl + defaultImage,
    url: baseUrl + pathname,
    keywords: keywords || defaultKeywords,
  };

  return (
    <Helmet title={seo.title} titleTemplate={`%s | ${defaultTitle}`}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:keywords" content={seo.keywords.join(", ")} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content={article ? "article" : "website"} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="og:image" content={seo.image} />
      <meta property="og:keywords" content={seo.keywords.join(", ")} />

      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  keywords: PropTypes.arrayOf(PropTypes.string),
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        defaultKeywords: keywords
        baseUrl: url
      }
    }
  }
`;

export default SEO;
