import React from "react";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaRegCopyright,
} from "react-icons/fa";

import SiteLinks from "./SiteLinks";
import LogoIcon from "../images/brand/icon/icon.svg";
import PaonixLogo from "../images/paonix.svg";

const Footer = () => {
  const getCopyrightDate = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2019;

    if (currentYear === startYear) {
      return currentYear;
    } else {
      return startYear + "-" + currentYear;
    }
  };

  return (
    <footer
      id="footer"
      className="bg-light text-dark border-t border-primary shadow"
    >
      <div className="lg:w-3/4 w-full mx-auto flex flex-wrap items-start  p-6">
        <div className="md:w-1/3 w-full mb-3" id="footer-logo">
          <img className="md:h-32 h-24" src={LogoIcon} alt="Logo (Icon)"></img>
        </div>
        <div className="md:w-1/3 w-full mb-3">
          <h1 className="text-2xl font-serif mb-3">Pages</h1>
          <div className="flex flex-col">
            <SiteLinks />
          </div>
        </div>
        <div className="md:w-1/3 w-full mb-3">
          <h1 className="text-2xl font-serif mb-3">Social</h1>
          <div className="text-3xl flex flex-col -mx-1">
            <a
              className="transition-standard text-blue-600 hover:text-blue-900 mx-1 -mt-2 mb-3"
              href="https://facebook.com/HealthLifelong"
            >
              <FaFacebookSquare className="inline mr-3" />
              <span className="text-lg">Facebook</span>
            </a>
            <a
              className="transition-standard text-purple-600 hover:text-purple-900 mx-1 -mt-2 mb-3"
              href="https://twitter.com/HealthLifelong"
            >
              <FaTwitterSquare className="inline mr-3" />
              <span className="text-lg">Twitter</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-primary text-light text-sm py-3 px-6 flex flex-wrap justify-between -mb-1 border-t border-dark">
        <div className="md:mb-0 mb-1">
          <p className="mb-1">
            <span className="opacity-75">
              Copyright
              <FaRegCopyright className="inline mx-1" />
              {getCopyrightDate()}
            </span>
            <span className="ml-1" style={{ opacity: ".99" }}>
              Lifelong Health and Fitness
            </span>
          </p>
          <p>
            <span className="opacity-75">EIN: </span>
            <span>83-4511932</span>
          </p>
        </div>
        <div className="transition-standard opacity-50 hover:opacity-100 -mt-1 md:ml-3 ml-0">
          <a href="https://www.paonix.com">
            Designed by
            <img
              className="h-8 inline pb-px"
              src={PaonixLogo}
              alt="Paonix LLC"
            ></img>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
