import React from "react";
import { Link } from "gatsby";

import { TiHeartFullOutline } from "react-icons/ti";

import SiteLinks from "./SiteLinks";
import LogoBanner from "../images/brand/banner/banner.svg";
import LogoIcon from "../images/brand/icon/icon.svg";
import "./HeaderHamburgers.scss";
import "./Header.css";

const Header = (props) => {
  const toggleMenu = () => {
    props.toggleMenu();
  };

  return (
    <header
      id="header"
      className="h-24 bg-light text-dark border-b shadow border-primary flex px-6 py-3"
    >
      <div
        id="header-menu-button"
        className="w-1/4 flex justify-start items-center"
      >
        <div
          className="absolute cursor-pointer text-lg uppercase px-3 py-2 bg-light border border-link transition-standard"
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          tabIndex={0}
          role="button"
        >
          <button
            className={`hamburger hamburger--collapse ${
              props.isMenuActive ? "is-active" : ""
            }`}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <span className="md:inline hidden ml-2">Menu</span>
        </div>
      </div>
      <div id="header-logo" className="h-full w-1/2 flex justify-center">
        <Link to="/" className="w-full flex justify-center">
          <img
            src={LogoBanner}
            alt="Logo (Banner)"
            className="md:block hidden h-full"
          ></img>
          <img
            src={LogoIcon}
            alt="Logo (Icon)"
            className="md:hidden block h-full"
          ></img>
        </Link>
      </div>
      <div
        id="header-donate-button"
        className="w-1/4 flex justify-end items-center"
      >
        <Link
          to="/donate"
          className="text-lg uppercase px-3 py-2 transition-standard border border-link"
        >
          <TiHeartFullOutline className="inline text-2xl" />
          <span className="md:inline hidden ml-2">Donate</span>
        </Link>
      </div>
      <nav
        id="header-menu"
        className={`transition-standard bg-primary border-r border-dark shadow ${
          props.isMenuActive ? "show" : "hide"
        }`}
      >
        <div className=" text-light flex flex-col items-end p-6 text-2xl leading-loose uppercase ">
          <SiteLinks />
        </div>
      </nav>
    </header>
  );
};

export default Header;
